<template>
  <v-dialog
    v-model="dialogAdminServicios.estatus"
    persistent
    max-width="600"
  >
    <v-card class="shadowCard">
		  <v-card-title primary-title>
		    <span class="text-subtitle-1">Administrar Cuentas</span>
		    <v-spacer></v-spacer>

        <v-btn 
          class="mr-2" 
          small dark color="green"
          @click="exportExcel( niveles  , 'escuelas_ERP')"
          tile
        >
          <v-icon small>mdi-microsoft-excel</v-icon>
        </v-btn>

        <v-btn
          color="orange"
          dark
          class="mr-2"
          @click="dialog = true"
          small
          tile
        >
          <v-icon small>mdi-plus</v-icon>
        </v-btn>

        <v-btn
          color="primary"
          dark
          class="text-capitalize"
          @click="initialize()"
          small
          tile
        >
          <v-icon small>mdi-refresh</v-icon>
        </v-btn>
        
		  </v-card-title>

		  <v-card-text>
		  	<v-row justify="end">
		  		<v-col cols="12" md="10">
		  			<v-text-field
		  			  name="name"
		  			  label="Buscar"
		  			  id="id"
		  			  filled
		  			  dense
		  			  single-line
		  			  hide-details
		  			  append-icon="mdi-magnify"
		  			  v-model="search"
		  			>
		  			</v-text-field>
		  		</v-col>
		  	</v-row>
		    <v-row>
		    	<v-col cols="12">
					  <v-data-table
					    :headers="headers"
					    :items="cuentas"
					    class="elevation-0"
					    :search="search"
					    :mobile-breakpoint="100"
	            dense
					  >

					    <template v-slot:item.actions="{ item }">
					    	<v-btn 
					    		color="primary" 
					    		x-small
					    		@click="editItem(item)"
					    		class="mr-2"
					    	>
						      <v-icon small>mdi-magnify</v-icon>
					    	</v-btn>

					    	<v-btn 
					    		color="error" 
					    		x-small
					    		@click="deleteItem(item)"
					    		class="mr-2"
					    	>
						      <v-icon small> mdi-delete</v-icon>
					    	</v-btn>
					    </template>

					    <template v-slot:no-data>
					      <v-btn
					        color="primary"
					        @click="initialize"
					        small
					      >
					        Actualizar
					      </v-btn>
					    </template>

					  </v-data-table>
		    	</v-col>
		    </v-row>
		  </v-card-text>
		  <v-card-actions>
		  	<v-btn 
		  		color="black"
		  		dark
		  		small
		  		tile
		  		@click="$emit('getServicios')"
		  	>
		  		<v-icon small left>mdi-close</v-icon>
		  		Cerrar
		  	</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
		</v-card>

    <!-- Dialogo de editar o agregar-->
	  <v-dialog
      v-model="dialog"
      max-width="400px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
          	<strong>ID: {{ editedItem.idservicios }}</strong>
          </span>
        </v-card-title>

        <v-card-text>

      		<label>Servicio: </label>
          <v-text-field
            v-model="editedItem.servicio"
            label="Servicio"
            filled
            single-line
            dense
          ></v-text-field>

         	<label>Costo: </label>
          <v-text-field
            v-model="editedItem.costo"
            label="Costo"
            filled
            single-line
            dense
          ></v-text-field>
          
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="black"
            dark
            small
            @click="close"
            tile
            dense
          >
          	<v-icon left small>mdi-close</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            small
            dark
            @click="save"
            tile
          >
          	<v-icon left small>mdi-content-save</v-icon>
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo para eliminar -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
      	<v-card-text>
	      	<v-row justify="space-around">
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
		      				<strong>Eliminar</strong> ¿Estás seguro que deseas eliminar los datos?
			      			<v-card-actions class="pt-6">
			      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
			      			  <v-btn color="error" text  class="mr-2" @click="closeDelete()">No, cancelar</v-btn>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="#5C5C5C"  dark class="elevation-6"  @click="deleteItemConfirm()">Si</v-btn>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="5">
	      			<v-img src="@/assets/borrar.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-dialog>
</template>
<script>
import axios from 'axios';
import { mapGetters } from "vuex";
// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga from "@/components/alertas/carga.vue";

import validarErrores from "@/mixins/validarErrores";
import funcionesExcel from "@/mixins/funcionesExcel";

export default {

  mixins: [ validarErrores, funcionesExcel ],

  props: ['dialogAdminServicios','escuela'],

  components: {
    Alerta,
    carga,
  },

  data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,
      
      editedIndex: -1,

      editedItem: {
        idservicios:0,
				servicio:'',
        costo:0,
				deleted:0,
				fecha_creacion: null,
      },

      defaultItem: {
        idservicios:0,
				servicio:'',
        costo:0,
				deleted:0,
				fecha_creacion: null,
      },

			search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      cuentas: [],
      headers: [
        { text: 'ID'         , value: 'idservicios'  },
        { text: 'Servicio'   , value: 'servicio'     },
        { text: 'Costo'      , value: 'costo'        },
        { text: 'Actions'    , value: 'actions', sortable: false },
      ],

      pdf: null,
			dialogPDF: false,
	    
	    archivos:null,
	    vistaPrevia: null,
	    file: '',
	    dragging: false,
	    dialgImagen:false,
			url_servidor: '',
			fullscreen: false,
			pdf:null,
			dialogPDF: false,
			
			imagen: null,
			extension:'',
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar servicio' : 'Editar servicio'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },

    },

    async created () {
      await this.initialize()
    },

    methods: {
      initialize () {
      	this.cargar = true
        this.cuentas = []
        return this.$http.get('admin.servicios.list').then(response=>{
        	this.cuentas = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      editItem (item) {
        this.editedIndex = this.cuentas.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.cuentas.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
      	this.cargar = true
        // Lo mandapos por el EP
        this.editedItem.deleted = 1
        this.$http.put('admin.servicios.update/' + this.editedItem.idservicios, this.editedItem).then(response=>{
        	this.cargar = true
        	this.closeDelete()
        	this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      async save () {
      	// Cargamos al usuario que hizo el cambio
	      this.cargar = true

        if (this.editedIndex > -1) {
	        // Lo mandapos por el EP
          this.$http.put('admin.servicios.update/' + this.editedItem.idservicios, this.editedItem).then(response=>{
          	this.validarSuccess( response.data.message )
        		this.close()
          	this.initialize()
	        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

        } else {
	        // Lo mandapos por el EP
          this.$http.post('admin.servicios.add', this.editedItem).then(response=>{
          	this.initialize()
        		this.close()
	        	this.cargar = false
	        }).catch( error =>{
          	this.validarError( error.response.data.message )
        	}).finally( () => { this.cargar = false })
        }
      },
    },
  }
</script>
