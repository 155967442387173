<template>
  <v-container fluid class="mt-4 ml-2 pr-6">
    <!--  -->
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title class="text-subtitle-1">
            Panel de Servicios
            <v-spacer></v-spacer>

            <!-- Boton para descargar el excel -->
            <v-btn
              class="mr-2"
              small
              dark
              color="green"
              @click="exportExcel(filterNomina, 'Egresos')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>


            <v-btn
		          color="orange"
		          dark
		          class="mr-2"
		          @click="dialog = true"
		          small
		          tile
		        >
		          <v-icon small>mdi-plus</v-icon>
		          Add Pago
		        </v-btn>

            <!-- Administrar cuentas -->
            <v-btn
              class="mr-2"
              small
              dark
              color="orange"
              @click="dialogAdminServicios.estatus = true"
              tile
            >
              <v-icon small>mdi-plus</v-icon>
              Admin. Cuentas
            </v-btn>

            <v-btn color="primary" @click="initialize()" small tile>
              <v-icon small left>mdi-refresh</v-icon>
              Consultar
            </v-btn>

          </v-card-title>

          <v-card-text>
            <v-row justify="end">
              <v-col cols="12" md="6" lg="4">
                <v-text-field
                  filled
                  single-line
                  label="Búscar"
                  append-icon="mdi-magnify"
                  hide-details
                  v-model="search"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- REQUISICIONES -->
            <v-data-table
              :headers="headers"
              :items="serviciosPagados"
              dense
              class="elevation-0 mt-4"
              :search="search"
              item-class="clase_row"
            >
              <template v-slot:item.pagado="{ item }">
                <v-checkbox
                	small
                	hide-details
                	dense 
                	v-model="item.pagado" 
                	@click="updatePagado(item)"
                ></v-checkbox>
              </template>

              <template v-slot:item.fecha_pago="{ item }">
					    	<v-chip 
					    		v-if="item.diferencia_dias <= 5 && !item.pagado" 
					    		dark 
					    		small 
					    		color="red"
					    	>
					    		{{  item.fecha_pago }}
					    	</v-chip>

					    	<v-chip 
					    		v-if="item.diferencia_dias > 5 && item.diferencia_dias <= 10 && !item.pagado" 
					    		dark 
					    		small 
					    		color="orange"
					    	>
					    		{{  item.fecha_pago }}
					    	</v-chip>

					    	<v-chip 
					    		v-if="item.diferencia_dias > 10 && !item.pagado" 
					    		dark 
					    		small 
					    		color="grey"
					    	>
					    		{{  item.fecha_pago }}
					    	</v-chip>

					    	<v-chip 
					    		v-if="item.pagado" 
					    		dark 
					    		small 
					    		color="green"
					    	>
					    		{{  item.fecha_pago }}
					    	</v-chip>
					    </template>

              <template v-slot:item.actions="{ item }">
					    	<v-btn 
					    		color="primary" 
					    		x-small
					    		@click="editItem(item)"
					    		class="mr-2"
					    	>
						      <v-icon small>mdi-magnify</v-icon>
					    	</v-btn>

					    	<v-btn 
					    		color="error" 
					    		x-small
					    		@click="deleteItem(item)"
					    		class="mr-2"
					    	>
						      <v-icon small> mdi-delete</v-icon>
					    	</v-btn>
					    </template>

            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialogo de editar o agregar-->
	  <v-dialog
      v-model="dialog"
      max-width="400px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
          	<strong>ID: {{ editedItem.idservicios }}</strong>
          </span>
        </v-card-title>

        <v-card-text>

      		<label>Servicio: </label>
          <v-autocomplete
            filled
            dense
            clearable
            v-model="editedItem.idservicios"
            :items="servicios"
            label="Seleccione un servicio"
            item-text="servicio"
            item-value="idservicios"
            single-line
          >
          </v-autocomplete>

         	<label>Costo: </label>
          <v-text-field
            v-model="editedItem.fecha_pago"
            label="Fecha de pago"
            filled
            type="date"
            single-line
            dense
          ></v-text-field>
          
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="black"
            dark
            small
            @click="close"
            tile
            dense
          >
          	<v-icon left small>mdi-close</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            small
            dark
            @click="save"
            tile
          >
          	<v-icon left small>mdi-content-save</v-icon>
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <CatServicios  
      :dialogAdminServicios="dialogAdminServicios" 
      v-if="dialogAdminServicios.estatus"
      @getServicios="getServicios"
    />

    <!-- Dialog de carga -->
    <Alerta v-if="respuestaAlerta" :parametros="parametros" />
    <carga v-if="cargar" />
  </v-container>
</template>
<script>
import axios from 'axios';
import { mapGetters } from "vuex";

import { Clipboard } from "@capacitor/clipboard";

// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga from "@/components/alertas/carga.vue";

import EditarEgreso    from "@/components/egresos/EditarEgrego"
import CatServicios    from "@/components/egresos/CatServicios"

import validarErrores from "@/mixins/validarErrores";
import funcionesExcel from "@/mixins/funcionesExcel";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export default {
  components: {
    Alerta,
    carga,
    EditarEgreso,
    CatServicios
  },

  mixins: [validarErrores, funcionesExcel],

  data: () => ({
    // Alertas
    parametros: {
      dialogError: false,
      mensaje: "",
      color: "",
    },

    respuestaAlerta: false,
    loader: true,
    cargar: false,

    historialEgreso:[],

    dialogAdminServicios:{
    	estatus: false,
    },

    dialog: false,
    dialogDelete: false,
    
    editedIndex: -1,

    editedItem: {
      idservicios_pagado:0,
			idservicios:'',
      fecha_pago:0,
      pagado:null,
			deleted:0,
			fecha_creacion: null,
    },

    defaultItem: {
      idservicios_pagado:0,
			idservicios:'',
      fecha_pago:0,
      pagado:null,
			deleted:0,
			fecha_creacion: null,
    },

		search:'',
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    cuentas: [],
    headers: [
      { text: 'ID'            , value: 'idservicios_pagado'  },
      { text: 'Servicio'      , value: 'servicio'            },
      { text: 'Costo'         , value: 'costo'               },
      { text: 'Fecha de pago' , value: 'fecha_pago'          },
      { text: 'Pagado'        , value: 'pagado'              },
      { text: 'Actions'       , value: 'actions', sortable: false },
    ],

    serviciosPagados:[],
    servicios:[],
 
  }),

  computed: {
    ...mapGetters("login", ["getdatosUsuario"]),
    formTitle() {
      return this.editedIndex === -1 ? "Add recordatorio" : "Editar recordatorio";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  async created() {
    await this.initialize();
    await this.getServicios();
  },

  methods: {
    initialize() {
      this.serviciosPagados = [];
      this.cargar = true;
      return this.$http.get("admin.servicios.pagados.list").then((response) => {
        this.serviciosPagados = response.data;
        this.cargar = false;
      }).catch((error) => {
        this.validarError(error);
      }).finally(() => {
        this.cargar = false;
      });
    },

    getServicios () {
    	this.cargar = true
    	this.dialogAdminServicios.estatus = false
      this.servicios = []
      return this.$http.get('admin.servicios.list').then(response=>{
      	this.servicios = response.data
      	this.cargar      = false
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },

    editItem (item) {
      this.editedIndex = this.servicios.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.servicios.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
    	this.cargar = true
      // Lo mandapos por el EP
      this.editedItem.deleted = 1
      this.$http.put('admin.servicios.pagados.update/' + this.editedItem.idservicios_pagado, this.editedItem).then(response=>{
      	this.cargar = true
      	this.closeDelete()
      	this.initialize()
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    updatePagado( value ){
    	this.$http.put('admin.servicios.pagados.update/' + value.idservicios_pagado, value).then(response=>{
      	this.validarSuccess( response.data.message )
      	this.initialize()
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },

    async save () {
    	// Cargamos al usuario que hizo el cambio
      this.cargar = true

      if (this.editedIndex > -1) {
        // Lo mandapos por el EP
        this.$http.put('admin.servicios.pagados.update/' + this.editedItem.idservicios_pagado, this.editedItem).then(response=>{
        	this.validarSuccess( response.data.message )
      		this.close()
        	this.initialize()
        }).catch( error =>{
	        this.validarError( error.response.data.message )
	      }).finally( () => { this.cargar = false })

      } else {
        // Lo mandapos por el EP
        this.$http.post('admin.servicios.pagados.add', this.editedItem).then(response=>{
        	this.initialize()
      		this.close()
        	this.cargar = false
        }).catch( error =>{
        	this.validarError( error.response.data.message )
      	}).finally( () => { this.cargar = false })
      }
    },

  },
};
</script>
